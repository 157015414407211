
































import {Vue, Component, Prop} from 'vue-property-decorator'
import {BAvatar, BButton, BCard, BCol, BRow} from "bootstrap-vue";
import User = EmployeeAPI.User.User;
import {avatarText} from '@/helpers/filters/avatarText';

@Component({
  components: {BCard, BButton, BRow, BCol, BAvatar}
})
export default class UserInfoCard extends Vue {
  @Prop({
    default: () => {
      return {}
    }, required: true
  })
  item?: User;

  avatarText(value) {
    return avatarText(value)
  }
}
