



















































import {Vue, Component, Prop, Watch} from 'vue-property-decorator'
import UserInfoCard from "./user-info-card.vue";

import {
  BButton,
} from 'bootstrap-vue'
import vSelect from "vue-select";
import {isEqual} from "lodash";
import EmployeeSearchSelect from "@/apps/components/employee-search-select.vue";

@Component({
  components: {
    EmployeeSearchSelect,
    BButton,
    UserInfoCard,
    vSelect,
  }
})
export default class UserEditTabAccount extends Vue {
  @Prop({
    default: () => {
      return {}
    }, required: true
  })
  item?: EmployeeAPI.User.User;

  crmAccessMimicking: EmployeeAPI.User.User = null;
  @Watch("crmAccessMimicking")
  watchCrmAccessMimicking(){
    if(this.editedItem == null) return;
    if(this.crmAccessMimicking == null) return;
    this.$set(this.editedItem, "crmAccessMimicking", this.crmAccessMimicking.id);
    this.$set(this.editedItem, "crmAccessMimickingUser", this.crmAccessMimicking);
  }

  editedItem?: EmployeeAPI.User.User = null;

  roles: MaintenanceAPI.Role.Item[] = [];

  async created() {
    this.updateBreadcrumbs();

    const roles = await this.$api.maintenance.roles.GetList();
    this.$set(this, "roles", roles);
  }

  updateBreadcrumbs() {
    const breadcrumbs = this.$route.meta.breadcrumb;
    const userCrumb = {
      text: `${this.item.fullName}`,
      to: {name: 'employee-user', params: {identifier: this.item.id}},
      active: false,
    };

    if (breadcrumbs.length == 3) {
      breadcrumbs.splice(breadcrumbs.length - 1, 0, userCrumb);
    } else {
      this.$set(this.$route.meta.breadcrumb, 2, userCrumb)
    }
  }

  @Watch("item", {immediate: true, deep: true})
  watchItem() {
    //this.$set(this.item, "scopeGroups", this.item.scopeGroupItems);
    this.$set(this, "editedItem", {...this.item})
    this.$set(this, "crmAccessMimicking", this.item.crmAccessMimickingUser)
  }

  get hasItemBeenEdited(): boolean {
    return isEqual(this.item, this.editedItem)
  }

  async submitChanges() {
    const req = await this.$api.employee.user.Update(this.item.id, {
      roles: this.editedItem.roleItems.map(x => x.id),
      crmAccessMimicking: this.editedItem.crmAccessMimicking
    })

    if (req != null)
      this.$emit("reset");
  }
}
