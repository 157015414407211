









































































import {Vue, Component, Prop} from 'vue-property-decorator'
import {
  BTab, BTabs, BCard, BAlert, BLink,
} from 'bootstrap-vue'
import UserEditTabAccount from './components/user-edit-tab-account.vue'

/*import UserEditTabInformation from './UserEditTabInformation.vue'
import UserEditTabSocial from './UserEditTabSocial.vue'*/

@Component({
  components: {
    BTab,
    BTabs,
    BCard,
    BAlert,
    BLink,
    UserEditTabAccount,
    /*    UserEditTabInformation,
        UserEditTabSocial,*/
  }
})
export default class UserEdit extends Vue {
  item?: EmployeeAPI.User.User = null;

  get id() {
    return this.$route.params.identifier;
  }

  async mounted() {
    await this.getItem();
    this.$set(this.$route.meta, "breadcrumbs", [])
  }

  async getItem() {
    const user = await this.$api.employee.user.Get(this.id);
    this.$set(this, "item", user);
  }
}
